import { Box, Button, Rating } from '@mui/material'
import React from 'react'
import BorderedBox from '../../components/BorderedBox'
import TargetAudienceGroup from "../../assets/target-audience-grp.svg"
import ExpensesImage from "../../assets/expenses-image.svg"
import TataCompanyImage from "../../assets/tata-company-image.svg"
import ClientImage from "../../assets/client-image.svg"

const sectionHeadingStyle = {
   fontWeight:"700", fontSize:"2.625rem", lineHeight:"2.75rem", textAlign:"center"
}

const FeaturesAndClientReviewSection = () => {

  const clientTestimonials = [
    {image:ClientImage, name:"Amit Sharma", location:"Mumbai", review:"description description description description description description description", rating:"4"},
    {image:ClientImage, name:"Amit Sharma", location:"Mumbai", review:"description description description description description description description", rating:"3"},
    {image:ClientImage, name:"Amit Sharma", location:"Mumbai", review:"description description description description description description description", rating:"5"}
  ];

  const handlePlayClick = () => {
    const video = document.getElementById('videoElement');
    if (video) {
      video.play();
    }
  };

  return (
    <Box>

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
             <Box sx={{...sectionHeadingStyle, marginTop:"9.5rem"}}>
                <span style={{color:"#0081FF"}}>Streamline global employment</span> with Slatesy<br/>
                Employer of Record Platform
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                bguhiuhkijkljm
             </BorderedBox>
             <img src={TargetAudienceGroup} alt="target-audience-group" style={{marginTop:"7.5rem", width:"84.43rem", height:"37.187rem"}}/>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"12.375rem"}}>
             <Box sx={{...sectionHeadingStyle}}>
                Empower Your Workforce With <span style={{color:"#0081FF"}}>Advanced Tools</span>
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                Try Yourself!
             </BorderedBox>
             <Box sx={{width:"74.31rem", height:"45rem", borderRadius:"1rem",marginTop:"7.5rem", position: 'relative'}}>
                <video id="videoElement" style={{ width: "100%", height: "100%", borderRadius: "1rem", objectFit: 'cover'}} controls>
                    <source src="/path-to-your-video-file/video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <Box 
                   sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius:"1rem",
                           backgroundColor: '#000000B2', display: 'flex', justifyContent: 'center', alignItems: 'center' 
                    }}
                >
                    <Button 
                       onClick={handlePlayClick} 
                       sx={{backgroundColor:"#FFFEFA", textTransform:"none", borderRadius:"1.5rem", width:"21.32rem", height:"7.5rem", border:"1px solid #000000", 
                            fontSize:"1.125rem", lineHeight:"1.25rem", color:"#000"}}
                    >
                        Click for interactive demo
                    </Button>
                </Box>
             </Box>
        </Box>

        {/* <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"8.25rem"}}>
             <Box sx={{...sectionHeadingStyle}}>
               <span style={{color:"#0081FF"}}>Enjoy a seamless, responsive interface</span> <br/> experience across all devices.
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                agfsvhgdjn
             </BorderedBox>
             <Box sx={{width:"80.18rem", height:"34.18rem", borderRadius:"1rem", backgroundColor:"#FFFFFF", marginTop:"7.5rem"}}>

             </Box>
        </Box> */}

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"14.125rem", paddingInline:"3.125rem"}}>
             <Box sx={{...sectionHeadingStyle}}>
                 Save <span style={{color:"#0081FF"}}>money on travel!</span>
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                We have saved millions for our clients
             </BorderedBox>
             <Box sx={{width:"100%", display:"flex", alignItems:"stretch", gap:"1rem",height:"33.375rem", marginTop:"7.5rem"}}>
                <Box sx={{borderRadius:"10px", backgroundColor:"#fff", flex:1, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", paddingInline:"1.625rem"}}>
                    <img src={TataCompanyImage} alt="tata company image" style={{marginBottom:"1.68rem"}}/>
                    <Box sx={{fontSize:"1.25rem", lineHeight:"1.4375rem", fontWeight:"700", color:"#636363"}}>Ahshwnjej</Box>
                    <Box sx={{fontSize:"1rem", lineHeight:"1.9375rem", fontFamily:"Lato", textAlign:"center"}}>“Slatesy saved us at a large amount of discrepancies in managing  travel related reimbursements.”</Box>
                </Box>
                <img src={ExpensesImage} alt="expenses-image"/> 
             </Box>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"14.125rem", paddingInline:"3.125rem"}}>
             <Box sx={{...sectionHeadingStyle}}>
                See What Clients are Saying <span style={{color:"#0081FF"}}>About Us</span>
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                Testimonial
             </BorderedBox>
             <Box sx={{width:"100%", marginTop:"5.95rem", display:"flex", gap:"3rem", justifyContent:"space-between", marginBottom:"10.31rem"}}>
                {
                    clientTestimonials?.map((item)=>(
                       <Box sx={{width:"25.5rem", display:"flex", flexDirection:"column", alignItems:"center", borderRadius:"1.38rem", backgroundColor:"#FFFFFFD6", padding:"1.5rem", position: "relative"}}>
                          <img 
                             src={item?.image} 
                             style={{
                                height: "5rem",
                                width: "5rem",
                                position: "absolute",
                                top: "-2.5rem", 
                                borderRadius: "50%", 
                              }} 
                          />
                          <Box sx={{fontWeight:"600", fontSize:"1.375rem",lineHeight:"2.0625rem",color:"#1E1E1E"}}>{item?.name}</Box>
                          <Box sx={{fontFamily:"Lato", fontWeight:"600", fontSize:"0.875rem",lineHeight:"1.125rem",color:"#1E1E1E99"}}>{item?.location}</Box>
                          <Box  sx={{wordWrap: "break-word", overflow: "hidden", fontSize:"0.875rem",lineHeight:"1.68rem", marginBlock:"1rem"}}>
                            {item?.review}
                          </Box>
                          <Box>
                             <Rating value={item?.rating}/>
                          </Box>
                        </Box>
                    ))
                }
             </Box>
        </Box>

    </Box>
  )
}

export default FeaturesAndClientReviewSection