import { Box } from '@mui/system'
import React from 'react'
import BorderedBox from '../../components/BorderedBox'
import EmailInputWithButton from '../../components/EmailInputWithButton'
import Image1 from '../../assets/customer-company-image1.svg'
import Image2 from '../../assets/customer-company-image2.svg'
import Image3 from '../../assets/customer-company-image3.svg'
import Image4 from '../../assets/customer-company-image4.svg'
import Image5 from '../../assets/customer-company-image5.svg'
import Image6 from '../../assets/customer-company-image6.svg'
import Image7 from '../../assets/customer-company-image7.svg'


const MainSection = () => {

  const handleEmailSubmit = () =>{}

  return (
    <Box sx={{width:"100%", height:"60.625rem", borderRadius:"8px", display:"flex", flexDirection:"column", alignItems:"center", 
        paddingInline:"7.875rem",
        background: `linear-gradient(
            to bottom,
            rgba(0, 129, 255, 0.25) 0%,      
            rgba(194, 195, 248, 0.25) 0%,   
            rgba(0, 129, 255, 0.05) 100%,   
            rgba(194, 195, 248, 0.05) 100%   
          ),
          linear-gradient(
            to right,
            rgba(0, 129, 255, 0.25) 0%,
            rgba(194, 195, 248, 0.25) 100%
          )`
    }}>
       <BorderedBox sx={{height:"1.875rem", width:"13.75rem", marginTop:"9.5rem"}}>
           Keywords
       </BorderedBox>
       <Box sx={{fontWeight:"700",fontSize:"3.125rem", lineHeight:"4.375rem", textAlign:"center", marginTop:"1rem"}}>
            Streamline Your HR. Unleash Your Potential.<br/>
            Smarter HR, stronger workforce!
       </Box>
       <Box sx={{fontFamily:"Lato",fontSize:"1.25rem", lineHeight:"2rem", textAlign:"center", color:"#363636", marginTop:"1.5rem"}}>
            Streamline HR processes, enhance engagement, and drive success with<br/>
            our comprehensive employee lifecycle management solutions. 
       </Box>
       <EmailInputWithButton sx={{marginTop:"2.5rem"}} onSubmit={handleEmailSubmit}/>
       <Box sx={{marginTop:"2.5rem", borderRadius:"5.91px",border: "0.74px solid #0081FF", display:"flex", gap:"2.21rem",padding:"0.75rem 0"}}>
          {
            [{value:"8000",label:"Users"},{value:"8000",label:"Payslips/Month"},{value:"8000",label:"Companies"}].map((item, index)=>(
                <Box sx={{borderLeft: index !== 0 ? "1px solid #0081FF" : "none", padding:"0 0.75rem"}}>
                   <Box sx={{fontWeight:"700", fontSize:"1.29rem", color:"#0F1017"}}>
                    {item?.value}
                    <span style={{color:"#7152F3"}}>+</span>
                    </Box>
                   <Box sx={{fontFamily:"Lato", fontSize:"0.64rem", color:"#5E5E5E", letterSpacing:"0.02em", marginTop:"5px"}}>
                     {item?.label}
                   </Box>
                </Box>
            ))
          }
       </Box>
       <Box sx={{fontWeight:"500",fontSize:"1.5rem", lineHeight:"4.375rem", textAlign:"center", marginTop:"1rem"}}>
           Trusted by more than 1000+ companies.
       </Box>
       <Box sx={{display:"flex", justifyContent:"space-between", marginTop:"3rem", width:"100%"}}>
           {
            [Image1,Image2,Image3,Image4,Image5,Image6,Image7].map((companyImage)=>(
                <img index={companyImage} src={companyImage} alt={companyImage}/>
            ))
           }
       </Box>
    </Box>
  )
}

export default MainSection