import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';  
import Footer from '../components/Footer';  
import { Box } from '@mui/material';

const RootLayout = () => {
  return (
    <Box sx={{display: 'flex', flexDirection: 'column',}}>
      <Navbar />
      <Box sx={{marginTop:"4.93rem"}}>
          <Outlet /> 
      </Box>
      <Footer />
    </Box>
  );
}

export default RootLayout;
