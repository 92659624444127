import { Box, Button } from '@mui/material'
import React from 'react'
import SlatesyDashboard from '../../assets/slatesy-dashboard.svg'
import BorderedBox from '../../components/BorderedBox'
import CarouselCard from '../../components/CarouselCard'
import CardImage from '../../assets/card-image.svg'

const WhatWeDoSection = () => {

  const features = [
     {image:CardImage, title:"Leave Management", description:"description description description description"},
     {image:CardImage, title:"Onboarding", description:"description description description description"},
     {image:CardImage, title:"Payroll and Analytics", description:"description description description description"},
     {image:CardImage, title:"Attendance and Timesheet", description:"description description description description"}, 
     {image:CardImage, title:"Expense Management", description:"description description description description"},
     {image:CardImage, title:"Trips", description:"description description description description"}
  ];

  let box = document.querySelector('.card-container');

  const btnPressPrev = () =>{
   let width = box.clientWidth;
   box.scrollLeft = box.scrollLeft - width;
  }

  const btnPressNext = () =>{
   let width = box.clientWidth;
   box.scrollLeft = box.scrollLeft + width;
   console.log(width)
  } 

  return (
    <Box sx={{width:"100%", height:"71.31rem", backgroundColor:"#0081FF1A", padding:"9.5rem 6rem 9.5rem 2.5rem"}}>
         <Box sx={{display:"flex", justifyContent:"space-between"}}>
               <img src={SlatesyDashboard} alt={"slatesy dashboard"} style={{width:"55.5rem", height:"34.875rem"}}/>
               <Box sx={{width:"19.93rem", display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <Box sx={{fontSize:"2.25rem", fontWeight:"700"}}>
                     Get Slatesy <span style={{color:"#0081FF"}}>fied</span>
                  </Box>
                  <BorderedBox sx={{width:"9.875rem", height:"1.875rem", padding:"4px 0px", marginTop:"1rem"}}>
                    No pun intended
                  </BorderedBox>
                  <Box sx={{marginTop:"6.25rem", fontSize:"1rem", lineHeight:"2rem", textAlign:"justify",ol: {paddingLeft: "1rem"}}}>
                        Why are we different from other HRMS portals?
                        <ol>
                            <li>djhbcfjdvbrfjbvkjf</li>
                            <li>bdshdchbcfhdbcfhdc</li>
                            <li>bujvbxhysvxgcvsgcc</li>
                            <li>bvhyvhyvyhvhygvhvh</li>
                            <li>vhvhyvhvhvghvhvhvh</li>
                        </ol>
                  </Box>
               </Box>
         </Box>
         <Box sx={{marginTop:"8rem",  position:"relative", overflow:"hidden", height:"17.0625rem", display:"flex", alignItems:"center", }}>
               <Button 
                 sx={{fontWeight:"500", borderRadius:"50%", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"}} 
                 onClick={btnPressPrev}
               >
                  &lt;
               </Button>
               <Button 
                 sx={{fontWeight:"500", position:"absolute", right:0, borderRadius:"50%",boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"}} 
                 onClick={btnPressNext}
               >
                  &gt;
               </Button>
               <Box 
                 sx={{display: "flex" , gap:"3.84rem", overflowX: "hidden", scrollBehavior: "smooth"}}
                 className="card-container"
               >
                {
                    features.map((item)=>(
                       <CarouselCard>
                          <Box sx={{display:"flex", gap:"1.21rem"}}>
                              <img src={item?.image} alt="card image"/>
                              <Box sx={{fontWeight:"600", fontSize:"1.3125rem", lineHeight:"2.15rem"}}>
                                 {item?.title}
                              </Box>
                          </Box>
                          <Box sx={{fontFamily:"Lato", fontSize:"1rem", lineHeight:"1.4rem", paddingBlock:"1.4rem", marginTop:"2.8125rem"}}>
                                 {item?.description}
                          </Box>
                       </CarouselCard>
                    ))
                }
               </Box>
         </Box>
    </Box>
  )
}

export default WhatWeDoSection