import React from 'react';
import LandingPage from './pages/landing-page/index';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,  
    children: [
      {
        path: '/',
        element: <LandingPage /> 
      }
     
    ]
  }
]);

const App = () => {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
