import React from 'react';
import { Box, Link, IconButton } from '@mui/material';
import { Twitter, Instagram, Email, YouTube } from '@mui/icons-material';
import EmailInputWithButton from './EmailInputWithButton';

const columnHeadingStyle={
  fontWeight:"600",
  fontSize:"1.25rem",
  lineHeight:"1.875rem",
  color:"#141414"
}

const columnItemStyle={
   fontSize:"1rem",
   lineHeight:"1.5rem",
   color:"#141414"
}

const iconButtonStyle={
  backgroundColor: '#0081ff', 
  color: '#ffffff', 
  borderRadius: '50%', 
  padding: '10px', 
  '&:hover': { backgroundColor: '#006bbd' } 
}

const Footer = () => {

  const handleEmailSubmit = () =>{}

  return (
    <Box 
      component="footer" 
      sx={{ backgroundColor: '#E5F2FF', padding: '11.31rem 4.43rem 3.25rem', position: 'relative', display:"flex", flexDirection:"column", alignItems:"center" }}
    >
      
      <EmailInputWithButton sx={{width:"48.25rem", height:"5.1875rem", borderRadius:"2rem", backgroundColor:"#E5F2FF"}} onSubmit={handleEmailSubmit}/>

      <Box sx={{width:"100%", display: 'flex',justifyContent: 'space-between',marginTop:"7.625rem"}}>

        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={columnHeadingStyle}>Contact Us</Box>
          <Link href="#" underline="hover" sx={columnItemStyle}>contactus@slatesy.com</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Ph. No: +91-8412017610</Link>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={columnHeadingStyle}>Products</Box>
          <Link href="#" underline="hover" sx={columnItemStyle}>Payroll</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Time & Attendance</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Onboarding</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Reporting</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Benefits</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Expenses</Link>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={columnHeadingStyle}>Company</Box>
          <Link href="#" underline="hover" sx={columnItemStyle}>Vision</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Values</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Success Stories</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Our Team</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Join Us</Link>
          <Link href="#" underline="hover" sx={columnItemStyle}>Invest in Us</Link>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={columnHeadingStyle}>Demo</Box>
          <Link href="#" underline="hover" sx={columnItemStyle}>Schedule Demo</Link>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={columnHeadingStyle}>Pricing</Box>
          <Link href="#" underline="hover" sx={columnItemStyle}>Chat with us</Link>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={columnHeadingStyle}>Resources</Box>
          <Link href="#" underline="hover" sx={columnItemStyle}>Blogs</Link>
        </Box>
      </Box>

      <Box sx={{width:"100%", display: 'flex', justifyContent: 'space-between',alignItems: 'center',marginTop:"4.25rem"}}>
        <Box sx={{ fontSize: '1rem',lineHeight:"1.5rem", color: '#141414' }}>@Slatesy, 2024 India</Box>

        <Box>
          <Box sx={{ fontWeight: '600', fontSize:"1.25rem", lineHeight:"1.875rem", marginBottom:"1rem"}}>FOLLOW US</Box>
          <Box sx={{ display: 'flex', gap:"0.5rem", alignItems: 'center' }}>
            <IconButton href="#" sx={{...iconButtonStyle}}>
              <Twitter/>
            </IconButton>
            <IconButton href="#" sx={{...iconButtonStyle}}>
              <Instagram />
            </IconButton>
            <IconButton href="#" sx={{...iconButtonStyle}}>
              <Email />
            </IconButton>
            <IconButton href="#" sx={{...iconButtonStyle}}>
              <YouTube />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
