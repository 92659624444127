import React from 'react'
import { Box, Button } from '@mui/material'
import SlatesyIcon from '../assets/slatesy-icon.svg'
import PrimaryButton from './PrimaryButton'

const Navbar = () => {
  return (
    <Box sx={{
              width:"100%", 
              display:"flex", 
              alignItems:"center", 
              justifyContent:"space-between", 
              padding:"1.125rem 2rem", 
              boxShadow: "0px 9px 20px 0px #0000001A",
              position: "fixed",      
              top: 0,                
              left: 0,                
              zIndex: 1000,           
              backgroundColor: "#fff" 
            }}>
         <img src={SlatesyIcon} alt="slatesy-icon"/>
         <Box sx={{display:"flex", gap:"4rem"}}>
            <Box sx={{padding:"0.625rem",fontSize: "1.125rem",lineHeight: "1.25rem",fontWeight: 500,}}>
                Products
            </Box>
            <Box sx={{padding:"0.625rem",fontSize: "1.125rem",lineHeight: "1.25rem",fontWeight: 500,}}>
                About
            </Box>
            <Box sx={{padding:"0.625rem",fontSize: "1.125rem",lineHeight: "1.25rem",fontWeight: 500,}}>
                Pricing
            </Box>
            <Box sx={{padding:"0.625rem",fontSize: "1.125rem",lineHeight: "1.25rem",fontWeight: 500,}}>
                Resources
            </Box>
         </Box>
         <Box sx={{display:"flex", gap:"2.25rem"}}>
            <Button sx={{
                fontSize: "1.125rem",
                lineHeight: "1.25rem",
                fontWeight: 500,
                color:"#000",
                textTransform: "none",
                padding:"0.625rem 1.375rem"
            }}>
                Login
            </Button>
            <PrimaryButton sx={{borderRadius:"10px", padding:"0.625rem 1.375rem"}}>
                Demo
            </PrimaryButton>
         </Box>
    </Box>
  )
}

export default Navbar